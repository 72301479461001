<template>
    <el-main>
        <el-form class="el-form-search" label-width="120px">
            <el-form-item label="用户账号：">
                <el-input size="small" type="number" v-model="searchForm.u_mobile" placeholder="请输入用户账号"></el-input>
            </el-form-item>
            <el-form-item label="店铺名称：">
                <el-input size="small" v-model="searchForm.store_name" placeholder="请输入店铺名称"></el-input>
            </el-form-item>
            <el-form-item label="商家姓名：">
                <el-input size="small" v-model="searchForm.merchant_name" placeholder="请输入商家姓名"></el-input>
            </el-form-item>
            <el-form-item label="商家电话：">
                <el-input size="small" type="number" v-model="searchForm.merchant_mobile" placeholder="请输入商家电话"></el-input>
            </el-form-item>
            <el-form-item label="申请时间：">
                <el-date-picker size="small" v-model="searchForm.start_time" type="daterange" range-separator="~"
                    start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </el-form-item>
            <el-form-item label=" " label-width="25px">
                <el-button type="primary" size="small" @click="search">搜索</el-button>
                <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
            <template slot="empty">
                <No />
            </template>
            <el-table-column prop="u_mobile" label="用户账号" width="110" align="center"></el-table-column>
            <el-table-column prop="store_name" label="店铺名称" align="center"></el-table-column>
            <el-table-column prop="classify_name" label="商家分类" width="100" align="center"></el-table-column>
            <el-table-column prop="merchant_name" label="商家姓名" align="center" width="120"></el-table-column>
            <el-table-column prop="merchant_mobile" label="商家电话" width="110" align="center"></el-table-column>
            <el-table-column prop="coupon_rate" label="结算比例" align="center" width="80">
                <template v-slot="{ row }">
                    {{ row.coupon_rate }}%
                </template>
            </el-table-column>
            <el-table-column prop="goods_order_amount" label="商品订单总金额" align="center" width="120">
                <template v-slot="{ row }">
                    ￥{{ row.goods_order_amount }}
                </template>
            </el-table-column>
            <el-table-column prop="goods_order_sum" label="	商品订单总数" align="center" width="110"></el-table-column>
            <el-table-column prop="goods_total_amount" label="商品收益总金额" align="center" width="120">
                <template v-slot="{ row }">
                    ￥{{ row.goods_total_amount }}
                </template>
            </el-table-column>
            <el-table-column prop="total_amount" label="总收益金额" align="center" width="90">
                <template v-slot="{ row }">
                    ￥{{ row.total_amount }}
                </template>
            </el-table-column>
            <el-table-column prop="yet_withdraw_amount" label="成功提现金额" align="center" width="110">
                <template v-slot="{ row }">
                    ￥{{ row.yet_withdraw_amount }}
                </template>
            </el-table-column>
            <el-table-column prop="create_time" label="申请时间" width="200" align="center">
                <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
            </el-table-column>
            <el-table-column label="操作" align="center" fixed="right" width="200px">
                <template v-slot="{ row }">
                    <el-button @click="getAccount(row.id)" type="text" size="small">登录账号</el-button>
                </template>
            </el-table-column>
        </el-table>
        <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData">
        </Paging>
        <el-dialog title="登录账号" :visible.sync="showInfo" width="500px">
            <el-form label-width="140px">
                <el-form-item label="登录网址：">
                    <el-link :href="account.url" target="_blank" type="primary" :underline="false"> {{ account.url
                    }}</el-link>
                </el-form-item>
                <el-form-item label="登录账号：">{{ account.staff_account }}</el-form-item>
                <el-form-item label="登录密码：">{{ account.staff_password }}</el-form-item>
            </el-form>
        </el-dialog>
    </el-main>
</template>

<script>
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
import _ from 'lodash';

export default {
    components: {
        Paging,
    },
    data () {
        return {
            list: [],
            total_number: 0,
            searchForm: {
                page: 1,
                rows: 10,
                u_mobile: '',
                store_name: '',
                merchant_name: '',
                merchant_mobile: '',
                start_time: '',
                end_time: '',
            },
            showInfo: !1,
            account: [],
        };
    },
    created () {
        this.getList();
    },
    methods: {
        getAccount (id) {
            this.$axios.post(this.$api.repair.store.getAccount, { id: id }).then(res => {
                if (res.code == 0) {
                    this.account = res.result;
                    if (this.account) {
                        this.showInfo = !0;
                    } else {
                        this.$message.error('数据为空');
                    }
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        search () {
            this.searchForm.page = 1;
            this.getList();
        },
        clearSearch () {
            this.searchForm = {
                page: 1,
                rows: 10,
                u_mobile: '',
                store_name: '',
                merchant_name: '',
                merchant_mobile: '',
                start_time: '',
                end_time: '',
            };
            this.getList();
        },
        getDateformat (val) {
            return getDateformat(val);
        },
        updateData (val, status) {
            if (status == 0) {
                this.searchForm.rows = val;
                this.getList();
            } else {
                this.searchForm.page = val;
                this.getList();
            }
        },
        getList () {
            let searchForm = _.cloneDeep(this.searchForm);
            if (searchForm.start_time) {
                searchForm.end_time = searchForm.start_time[1] / 1000;
                searchForm.start_time = searchForm.start_time[0] / 1000;
            }
            this.$axios.post(this.$api.repair.store.list, searchForm).then(res => {
                if (res.code == 0) {
                    this.list = res.result.list;
                    this.total_number = res.result.total_number;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;

    .sortList {
        white-space: nowrap;
        display: flex;
        align-items: center;
    }
}

.el-main {
    background: #fff;
    display: flex;
    flex-direction: column;
}</style>
